import React, { useState } from "react";
import { graphql } from "gatsby";
import { t } from "../../components/Translate";

import Layout from "../../layouts/default";
import AboutContent from "../../components/AboutContent";

const About = () => {
  return (
    <Layout metaTitle={t("ABOUT_META_TITLE")}>
      <div className="container">
        <AboutContent />
      </div>
    </Layout>
  );
};
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default About;
